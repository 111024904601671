import React from "react"
import "./Gifts.scss"
import { AiFillGift } from "react-icons/ai"

// Swiper
import { Swiper, SwiperSlide } from "swiper/react"
import { FreeMode, Autoplay } from "swiper"
import "swiper/css"
import "swiper/css/free-mode"

import img1 from "../../assets/images/voucher.jpg"
import img2 from "../../assets/images/voucher2.jpg"
import img3 from "../../assets/images/voucher5.jpg"
import img4 from "../../assets/images/voucher4.jpg"

const Gifts = () => {
  return (
    <section className="Gifts">
      <AiFillGift className="icon" />
      <div className="title">KUP PREZENT</div>
      <div className="description">
        Wyjątkowy prezent na specjalne okazje. Podaruj szkolenie strzeleckie bliskiej osobie. Voucher jest indywidualnie drukowany dla osoby
        obdarowanej z Waszą wyjątkową dedykacją. Voucher na indywidualne szkolenie w dwóch wariantach. W celu ustalenia terminu i miejsca
        realizacji zakupionego prezentu prosimy o kontakt telefoniczny pod numerem 605 437 446.
      </div>
      <div className="galery">
        <Swiper
          spaceBetween={16}
          freeMode={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          loop={true}
          modules={[Autoplay, FreeMode]}
          breakpoints={{
            1600: { slidesPerView: 4 },
            1100: { slidesPerView: 3 },
            850: { slidesPerView: 2 },
            0: { slidesPerView: 1 },
          }}
        >
          <SwiperSlide>
            <img src={img1} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={img3} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={img2} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={img4} alt="" />
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  )
}

export default Gifts
