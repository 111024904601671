import React from "react"
import styled from "styled-components"

const CardFront = ({ title, img }) => {
  return (
    <Box>
      <img className="img" src={img} alt="" />
      <div className="title">{title}</div>
    </Box>
  )
}

export default CardFront

const Box = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  border-radius: 2rem;
  background-color: white;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  & > .title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2.2rem;
    font-weight: 700;
    color: white;
    font-style: italic;
  }

  & > .img {
    border-radius: 1rem;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
