import React from "react"
import { useLocation } from "react-router-dom"
import Logo from "../../Logo/Logo"
import { Link } from "react-router-dom"
import { CgClose } from "react-icons/cg"
import { FaInstagram, FaFacebookF } from "react-icons/fa"
import "./MobileNavbar.scss"

const MobileNavbar = ({ setNavOpen }) => {
  const { pathname } = useLocation()

  const scroll = (component) => {
    const delay = pathname === "/" ? 0 : 100
    setNavOpen(false)
    setTimeout(() => {
      const section = document.querySelector(`.${component}`)
      section.scrollIntoView()
    }, delay)
  }

  return (
    <section className="MobileNavbar">
      <CgClose
        className="colse-icon"
        onClick={() => {
          setNavOpen(false)
        }}
      />
      <div className="center">
        <Logo className="logo-img" />
        <div className="title">
          <p className="name">STRZELNICA BYDGOSZCZ</p>
          <p className="description">TRENINGI STRZELECKIE</p>
        </div>
        <div className="links">
          <Link to="/" className="link-red" onClick={() => scroll("Home")}>
            STRONA GŁÓWNA
          </Link>
          <Link to="/" onClick={() => scroll("Staff")}>
            O NAS
          </Link>
          <Link to="/" onClick={() => scroll("Gifts")}>
            KUP PREZENT
          </Link>
          <Link to="/" onClick={() => scroll("Offers")}>
            PAKIETY
          </Link>
          <Link to="/" onClick={() => scroll("Contact")}>
            KONTAKT
          </Link>
          <Link to="/stowarzyszenie" onClick={() => setNavOpen(false)}>
            STOWARZYSZENIE STRZELNICA BYDGOSZCZ
          </Link>
        </div>
        <div className="social-links">
          <a className="frame" href="https://www.instagram.com/strzelnica_bydgoszcz/" target="_blank">
            <FaInstagram className="social-link" />
          </a>
          <a className="frame" href="https://www.facebook.com/profile.php?id=100091530323055" target="_blank">
            <FaFacebookF className="social-link" />
          </a>
        </div>
      </div>
    </section>
  )
}

export default MobileNavbar
