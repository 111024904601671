import React from "react";
import { FaInstagram, FaFacebookF } from "react-icons/fa";
import { GiBullets } from "react-icons/gi";
import "./Offers.scss";
import Card from "./Card/Card";
// img
import img1 from "../../assets/images/soldier1.jpg";
import img2 from "../../assets/images/soldier2.jpg";
import img3 from "../../assets/images/soldier3.jpg";
import img4 from "../../assets/images/soldier4.jpg";
import img5 from "../../assets/images/soldier5.jpg";
import img6 from "../../assets/images/soldier6.jpg";
import img7 from "../../assets/images/soldier7.jpg";
//

const Offers = () => {
  return (
    <section className="Offers">
      <GiBullets className="img" />
      <div className="offer-title">Dostępne pakiety</div>
      <div className="title">
        Jesteś zdecydowany lub nie wiesz co wybrać zadzwoń{" "}
        <span style={{ whiteSpace: "nowrap" }}>605 437 446.</span> Pomożemy
        wybrać odpowiedni pakiet dla Ciebie. <br /> Zdjęcia naszej broni na
        profilu FB. <br />
        Strzelania realizujemy na strzelnicy Dynamic Arena w Smolnikach koło
        Szubina. <br />W cenie szkolenie z zasad bezpieczeństwa, opłata za
        strzelnicę, tarcze strzeleckie, ochronniki słuchu i wzroku oraz opieka
        instruktora.
      </div>
      <div className="cards">
        <Card
          img={img3}
          title="Pakiet dla dzieci i młodzieży 200zł"
          dataTitle="Pakiet zawiera strzelanie z broni:"
          data={[
            "Pistolet Sig Sauer 1911 kal 22 Lr (10 strzałów)",
            "Karabinek WINCHESTER kal 22 Lr (10 strzałów)",
            "Karabinek Rossi kal 22 Lr	(10 strzałów)",
            "Strzelba PUMP- ACTION Shotgun kal 22Lr (10 strzałów)",
            "Łącznie 40 strzałów",
          ]}
          footer=""
        />
        <Card
          img={img4}
          title="Pakiet 200zł"
          dataTitle="Pakiet zawiera strzelanie z broni:"
          data={[
            "Pistolet Sig Sauer 1911 kal 22 Lr (10 strzałów)",
            "Karabinek Rossi kal 22 Lr	(10 strzałów)",
            "Pistolet Glock kal 9 mm (10 strzałów)",
            "Karabinek Scorpion kal 9 mm (10 strzałów)",
            "Łącznie 40 strzałów",
          ]}
          footer=""
        />
        <Card
          img={img1}
          title="Pakiet 300zł"
          dataTitle="Pakiet zawiera strzelanie z broni:"
          data={[
            "Pistolet Glock kal 9 mm (10 strzałów)",
            "Rewolwer ASTRA kal  9 mm (6 strzałów)",
            "Karabinek Scorpion kal 9 mm (10 strzałów)",
            "Karabinek AR 15 Taurus T4 kal 223 REM (5 strzałów)",
            "Karabinek AK 47 SADU mini Dragunov (5 strzałów)",
            "Strzelba PUMP- ACTION Shotgun kal 17/70 (5 strzałów)",
            "Łącznie 41 strzałów",
          ]}
          footer=""
        />
        <Card
          img={img6}
          title="Pakiet 400zł"
          dataTitle="Pakiet zawiera strzelanie z broni:"
          data={[
            "Pistolet Glock kal 9 mm (10 strzałów)",
            "Rewolwer ASTRA kal  9 mm (6 strzałów)",
            "Karabinek Scorpion kal 9 mm (10 strzałów)",
            "Karabinek AR 15 Taurus T4 kal 223 REM (10 strzałów)",
            "Karabinek AK 47 SADU mini Dragunov (10 strzałów)",
            "Pistolet maszynowy PPS kal 7,62 (5 strzałów)",
            "Strzelba MOSSBERG 930 półautomat Shotgun (6 strzałów)",
            "Łącznie 57 strzałów",
          ]}
          footer=""
        />
        <Card
          img={img5}
          title="Pakiet MEGA 600zł"
          dataTitle="Pakiet zawiera strzelanie z broni:"
          data={[
            "Pistolet Glock kal 9 mm (10 strzałów)",
            "Rewolwer ASTRA kal  9 mm (6 strzałów)",
            "Karabinek Scorpion kal 9 mm (10 strzałów)",
            "Karabinek AR 15 Taurus T4 kal 223 REM (10 strzałów)",
            "Karabinek AK 47 SADU mini Dragunov (10 strzałów)",
            "Pistolet maszynowy PPS kal 7,62 (10 strzałów)",
            "Strzelba PUMP- ACTION Shotgun kal 17/70 (6 strzałów)",
            "Strzelba MOSSBERG 930 półautomat Shotgun (10 strzałów)",
            "Łącznie 72 strzały",
          ]}
          footer=""
        />
        <Card
          img={img7}
          title="Pakiet Strzelba 350zł"
          dataTitle="Pakiet zawiera strzelanie z broni:"
          data={[
            "Strzelba Mossberg 930 Snow goose półautomat (10 strzałów)",
            "Strzelba KEL TEC (John Wick) + kolimator (10 strzałów)",
            "Strzelba pojedynka Gladiator  (5 strzałów)",
            "Strzelba pump action Galdiator (10 strzałów)",
            "Łącznie 35 strzałów",
          ]}
          footer="Dostępny Pakiet MINI"
        />
        <Card
          img={img4}
          title="Pakiet Pistolet 400zł"
          dataTitle="Pakiet zawiera strzelanie z broni:"
          data={[
            "CZ P09 (10 strzałów)",
            "CZ SHADOW 2 (10 strzałów)",
            "CANIK METE PRO SFT + Kolimator   (10 strzałów)",
            "GLOCK 19 (10 strzałów)",
            "COLT 1911 kal. 45 (7 strzałów)",
            "GLOCK 44  (10 strzałów)",
            "Rewolwer ASTRA (10 strzałów)",
            "WALTHER P 99 (policyjny) (10 strzałów)",
            "Łącznie 77 strzałów",
          ]}
          footer="Dostępny Pakiet MINI"
        />
        <Card
          img={img1}
          title="Pakiet Karabin 500zł"
          dataTitle="Pakiet zawiera strzelanie z broni:"
          data={[
            "AR 15 TIPMAN (10 strzałów)",
            "PPS (10 strzałów)",
            "Karabinek CSV (10 strzałów)",
            "Karabinek CELIK ARMS (10 strzałów)",
            "Karabinek AKMS (10 strzałów)",
            "Karabinek AR 15 TAURUS (10 strzałów)",
            "Karabinek MSBS GROT (10 strzałów)",
            "Łącznie 70 strzałów",
          ]}
          footer="Dostępny Pakiet MINI"
        />
        <Card
          img={img6}
          title="Pakiet Snajper 500zł"
          dataTitle="Pakiet zawiera strzelanie z broni:"
          data={[
            "Karabin 308 WIN Z OPTYKĄ (10 strzałów)",
            "Karabinek MSBS GROT Z OPTYKĄ (10 strzałów)",
            "Karabinek MINI DRAGUNOW Z OPTYKĄ (10 strzałów)",
            "Łącznie 30 strzałów",
            "Strzelanie odbywa sie na odległość 100m",
          ]}
          footer="Dostępny Pakiet MINI"
        />
        <Card
          img={img7}
          title="Pakiet Ekstremalny 500zł"
          dataTitle="Pakiet zawiera strzelanie z broni:"
          data={[
            "Karabin  308 WIN (10 strzałów)",
            "Pistolet COLT 1911 kal. 45 ACP (7 strzałów)",
            "Strzelba MAGNUM  (5 strzałów)",
            "Rewolwer 454 CASULL (5 strzałów)",
            "Łącznie 27 strzałów",
            "Strzelanie odbywa się tylko po podpisaniu oświadczenia o zapoznaniu z ryzykiem.",
          ]}
          footer="Dostępny Pakiet MINI"
        />
        <Card
          img={img3}
          title="Pakiet pozwolenie na broń 1200zł"
          dataTitle=""
          data={[
            `Pakiet w którym w pełni przygotujemy Cię do egzaminu praktycznego na pozwolenie sportowe, kolekcjonerskie do ochrony osobistej oraz dopuszczenie do broni. 
            W cenie pakietu zawierają się wszystkie koszty tj: broń, amunicja, opłaty za strzelnice, tarcze, ochronniki wzorku słuchu.
            Szkolenie trwa ok 8 godzin i może być realizowane w różnych terminach. Posiadamy broń która używana jest na egzaminach w Bydgoszczy.`,
          ]}
          footer=""
        />
        <Card
          img={img2}
          title="Szkolenia i Imprezy okolicznościowe"
          dataTitle="Oferujemy również:"
          data={[
            "Szkolenia:",
            "indywidualne, grupowe, dla szkół mundurowych i nie tylko.",
            "Imprezy okolicznościowe:",
            "kawalerskie, panieńskie, urodzinowe, integracyjne, ",
            "firmowe, spotkanie klasowe, świąteczne i inne.",
          ]}
          footer="Oferowany zakres usługi uzależniony jest od wymagań i oczekiwań zamawiającego. Na podstawie posiadanego budżetu planujemy indywidulanie przebieg imprezy. Zazwyczaj koszt imprezy zaczyna się od 250 zł od osoby"
        />
        {/* <Card
          img={img2}
          title="Imprezy okolicznościowe"
          dataTitle="Organizujemy imprezy okolicznościowe:"
          data={["Kawalerskie, Panieńskie, Urodzinowe,", "Integracyjne, Firmowe,", "Spotkanie klasowe,", "Świąteczne i inne"]}
          footer="Oferowany zakres usługi uzależniony jest od wymagań i oczekiwań zamawiającego. Na podstawie posiadanego budżetu planujemy indywidulanie przebieg imprezy. Zazwyczaj koszt imprezy zaczyna się od 250 zł od osoby, w ramach ceny oferujemy strzelanie dla uczestników, ognisko lub grill, kiełbasa, kawa, herbata."
        /> */}
      </div>
      <div className="title">
        Zachęcamy Cię do śledzenia naszych działań oraz pozostawania na bieżąco
        poprzez nasze profile na Instagramie i Facebooku.
      </div>
      <div className="social-links">
        <a
          className="frame"
          href="https://www.instagram.com/strzelnica_bydgoszcz/"
          target="_blank"
          rel="noreferrer"
        >
          <FaInstagram className="social-link" />
        </a>
        <a
          className="frame"
          href="https://www.facebook.com/profile.php?id=100091530323055"
          target="_blank"
          rel="noreferrer"
        >
          <FaFacebookF className="social-link" />
        </a>
      </div>
    </section>
  );
};

export default Offers;
