import React from "react"
import "./Undefined.scss"

const Undefined = () => {
  return (
    <main className="Undefined">
      <p className="status">404</p>
      <p className="description">Ta strona nie istnieje</p>
    </main>
  )
}

export default Undefined
