import React from "react";
import "./Partners.scss";
import Footer from "../../components/Footer/Footer";
import file from "../../assets/files/deklaracja.pdf";

const Partners = () => {
  return (
    <main className="Partners">
      <div id="text">
        <header>STOWARZYSZENIE STRZELNICA BYDGOSZCZ</header>
        <br />
        <p>
          Stowarzyszenie Strzelnica Bydgoszcz jest stowarzyszeniem o charakterze
          sportowo-kolekcjonerskim i zrzesza pasjonatów strzelectwa.
        </p>
        <p>
          Naszą misją jest stałe dążenie do rozwoju strzelectwa w naszym kraju,
          prowadzimy działania ukierunkowane na rozwój umiejętności bezpiecznego
          i celnego posługiwania się bronią wśród członków naszego
          stowarzyszenia.
        </p>
        <p>
          Służymy pomocą w uzyskaniu pozwolenia na broń, poprzez wsparcie
          merytoryczne i praktyczne w tym zakresie.{" "}
        </p>
        <p>
          Organizujemy wykłady i zajęcia praktyczne przygotowujące do egzaminów
          na pozwolenie na broń.
        </p>
        <p>
          Od samego początku prowadzimy naszych członków przez cały proces
          uzyskiwania pozwolenia na broń.{" "}
        </p>
        <p>
          Członkowie stowarzyszenia korzystają z atrakcyjnych rabatów na
          szkolenia, zakup broni u naszych partnerów oraz mają możliwość
          korzystania z broni należącej do stowarzyszenia.{" "}
        </p>
        <p>
          Członkowie stowarzyszenia posiadający swoją broń mają możliwość
          bezpłatnego uczestniczenia dwa razy w miesiącu w szkoleniach
          organizowanych przez stowarzyszenie. Opłata dotyczy jedynie opłat za
          strzelnicę i tarcze. Dla członków nieposiadających swojej broni
          oferujemy atrakcyjne warunki wypożyczenia broni i oporządzenia (kabur,
          ładownic, pasów strzeleckich…)
        </p>
        <p>
          Organizujemy kursy prowadzącego strzelanie i instruktorów strzelectwa.{" "}
        </p>
        <p>Po więcej informacji zadzwoń 782 919 406.</p>
        <div id="boss">
          <div id="position">Prezes Zarządu Stowarzyszenia</div>
          <div id="name">Tomasz Jaromin</div>
        </div>
        <div id="info">
          <div id="name">Stowarzyszenie Strzelnica Bydgoszcz </div>
          <div id="address">85-467 Bydgoszcz ul. Filtrowa 32/55</div>
          <div id="nip-regon">NIP 9671478259, REGON 529154392</div>
          <div id="krs">KRS 0001115390</div>
          <div id="krs">mBANK 52 1140 2004 0000 3202 8552 7171</div>
        </div>
        <div id="line"></div>
        <header>Jak zapisać się od Stowarzyszenia Strzelnica Bydgoszcz?</header>
        <ol>
          <li>
            Kliknij{" "}
            <a href={file} download="deklaracja.pdf">
              pobierz
            </a>{" "}
            i wypełnij deklarację.
          </li>
          <li>
            Prześlij wypełnioną deklarację na adres sb@strzelnicabydgoszcz.pl
          </li>
          <li>
            Wpłać składkę w kwocie 360 zł na konto z deklaracji z tytułem
            „składka członkowska imię i nazwisko”.
          </li>
          <li>Jeśli chcesz o coś zapytać zadzwoń 782 919 409.</li>
          <li>
            Po wpływie składki na konto potwierdzenie członkostwa prześlemy na
            maila i adres domowy z deklaracji.
          </li>
          <li>
            Wyślij zgłoszenie do grupy FB Stowarzyszenie Strzelnica Bydgoszcz.
          </li>
          <li>Zacznij korzystać z możliwości stowarzyszenia.</li>
        </ol>
      </div>
      <Footer />
    </main>
  );
};

export default Partners;

// wojski.com.pl
